import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-black text-white p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">
          <img
            className=""
            src="https://avatars.githubusercontent.com/u/32179889?s=200&v=4"
            height={50}
            width={50}
            alt="Logo"
          />
        </div>

        <div className="hidden md:flex md:items-center md:justify-center flex-grow">
          <div className="flex space-x-6">
            <a
              href="#"
              className="interact-button connectButtun text-orange-300 hover:text-pink-300">
              [FIRMWARE] 5.8.0
            </a>
          </div>
        </div>

        <div className="flex items-center">
          <div className="md:hidden mr-2">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="focus:outline-none">
              {isOpen ? (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={`md:hidden ${isOpen ? "block" : "hidden"} mt-2`}>
        <div className="flex flex-col space-y-2">
          <a href="#" className="hover:text-gray-300">
            FIRMWARE 5.8.0
          </a>
        </div>
      </div>
    </nav>
  );
};

const Illustration = () => {
  return (
    <div className="flex justify-center py-2">
      <img
        src="https://cdn.prod.website-files.com/5f734f4dbd95382f4fdfa0ea/65ec78f2a33bbb507957c29d_Equivalent%20JSON-RPC%20API%C2%A0.gif"
        height={150}
        width={180}
        alt="Illustration"
      />
    </div>
  );
};

const Logo = () => {
  return (
    <div className="flex justify-center py-6">
      <h1>
        <span className="text-white-300 text-lg">BIP39/BIP44</span> WALLET
        MIGRATION
      </h1>
    </div>
  );
};

const MigrationStart = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleStartMigration = () => {
    setIsProcessing(true);
    // Here you would typically start your migration process
    // For demonstration, we're just setting a loading state
  };

  return (
    <div className="p-6 bg-gray-800 rounded-lg shadow-xl">
      <div className="mb-4">
        <h2 className="text-2xl font-bold text-white">Wallet Migration</h2>
        <p className="text-gray-400">
          {isProcessing
            ? "Migration in progress"
            : "Ready to start your wallet migration"}
        </p>
      </div>
      <div className="flex flex-col items-center">
        {isProcessing ? (
          <>
            <div className="w-16 h-16 mb-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            <p className="text-center text-gray-300">
              Migration in progress. Please do not close this window.
            </p>
          </>
        ) : (
          <p className="text-center text-gray-300 mb-4">
            Click the button below to begin the migration process.
          </p>
        )}
      </div>
      <div className="interact-button mt-6">
        <button
          onClick={handleStartMigration}
          disabled={isProcessing}
          className="interact-button w-full p-2 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed">
          {isProcessing ? "Migration in Progress..." : "Start Migration"}
        </button>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      <Illustration />
      <div className="flex items-center justify-center py-10">
        <div className="w-full max-w-4xl bg-gray-900 rounded-lg shadow-xl overflow-hidden">
          <Logo />
          <MigrationStart />
          <div className="flex justify-between p-6"></div>
        </div>
      </div>
    </div>
  );
};

export default App;
